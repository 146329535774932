import { FaShopify } from "react-icons/fa";
import { FiGrid, FiUsers, FiUser, FiCompass, FiSettings, FiSlack, FiGlobe, FiTarget } from "react-icons/fi";
import { ImProfile } from "react-icons/im";
import { PiFlagBannerFill } from "react-icons/pi";
import { FaHandsHelping } from "react-icons/fa";
/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const sidebar = [
  {
    path: "/dashboard", // the url
    icon: FiGrid, // icon
    name: "Dashboard", // name that appear in Sidebar
  },
  {
    path: "/orders",
    icon: FiCompass,
    name: "Orders",
  },
  {
    icon: FiSlack,
    name: "Catalog",
    routes: [
      {
        path: "/products",
        name: "Products",
      },
      {
        path: "/categories",
        name: "Categories",
      },
      // {
      //   path: "/attributes",
      //   name: "Attributes",
      // },
      {
        path: "/coupons",
        name: "Coupons",
      },
      {
        path: "/inventory",
        name: "Inventory",
      },
      {
        path: "/attributes",
        name: "Attributes",
      },
    ],
  },
  {
    path: "/accounts", // the url
    icon: ImProfile, // icon
    name: "Accounts", // name that appear in Sidebar
  },
  {
    path: "/banners",
    icon: PiFlagBannerFill,
    name: "Banners",
  },
  {
    path: "/referals",
    icon: FiCompass,
    name: "Refferals",
  },
  {
    path: "/customers",
    icon: FiUsers,
    name: "Customers",
  },
  {
    path:"/enquiries",
    icon: FaHandsHelping,
    name: "Enquiries",
  }
];

export default sidebar;
