// your-zustand-store.js
import create from "zustand";

const useStore = create((set) => ({
  isOpen: false, 
  

  toggleSidebar: () => set((state) => ({ isOpen: !state.isOpen })),
}));

export { useStore };
